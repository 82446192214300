html,
body {
  height: 100%;
  margin: 0px;
  /* border:3px solid blue; */
}

#root {
  height: 100%;
}

.App {
  text-align: center;
  height: 100%;
  /* border:1px solid black; */
}

.floating-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 20px;
  top: 10px;
  border: 1px solid darkgray;
}
.main {
  width: 100%;
  height: 100%;
}
